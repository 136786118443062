import {
    SET_TEXT_SEARCH,
    SET_UNREAD_SEARCH,
    SET_RECIPIENT_CONVERSATIONS,
    SET_ACTIVE_RECIPIENT_CONVERSATION,
    SET_RECIPIENT_CONVERSATION_PAGINATION,
    SET_RECIPIENT_CURRENT_CONVERSATION_PAGE
} from "../mutation-types"

import RecipientConversations from "../../api/recipient-conversations"

const state = {
    recipientConversations: [],
    pagination: {
        current: 1,
        totalPages: [1],
        perPage: 10,
        total: 10,
    },
    activeRecipient: {
        first_name: '',
        last_name: ''
    },
    textSearch: false,
    unread: false,
}

const getters = {
    activeConversationRecipient: state => state.activeRecipient,
    recipientConversations: state => state.recipientConversations,
    recipientConversationPagination: state => state.pagination
}

const actions = {
    async getRecipientConversations({commit, state}) {
        const conversationClient = new RecipientConversations(state.pagination.current)
        let {data} = await conversationClient.get()

        commit(SET_RECIPIENT_CONVERSATION_PAGINATION, data)
        commit(SET_RECIPIENT_CONVERSATIONS, data.recipients.data)
        commit(SET_UNREAD_SEARCH, false)
        commit(SET_TEXT_SEARCH, false)
    },
    async getUnreadRecipientConversations({commit, state}) {
        const conversationClient = new RecipientConversations(state.pagination.current)
        let {data} = await conversationClient.getUnread()

        commit(SET_RECIPIENT_CONVERSATION_PAGINATION, data)
        commit(SET_RECIPIENT_CONVERSATIONS, data.recipients.data)
        commit(SET_UNREAD_SEARCH, true)
    },
    async getRecipientConversationsByQuery({commit, state}, query) {
        const conversationClient = new RecipientConversations(state.pagination.current)
        let {data} = await conversationClient.getByQuery(query)

        commit(SET_RECIPIENT_CONVERSATION_PAGINATION, data)
        commit(SET_RECIPIENT_CONVERSATIONS, data.recipients.data)
        commit(SET_TEXT_SEARCH, true)
    },
    setActiveRecipientConversation({commit, dispatch, state}, recipient) {
        if (!recipient?.id) {
            return Promise.resolve();
        }

        commit(SET_ACTIVE_RECIPIENT_CONVERSATION, recipient);
        
        return Promise.all([
            dispatch('conversations/readConversation', recipient.id, {root: true}),
            dispatch('conversations/getConversations', recipient.id, {root: true})
        ]);
    },
    setCurrentRecipientConversationPage({commit, dispatch}, page) {
        commit(SET_RECIPIENT_CURRENT_CONVERSATION_PAGE, page)
        dispatch('getRecipientConversations')
    }
}

const mutations = {
    [SET_ACTIVE_RECIPIENT_CONVERSATION](state, recipient) {
        state.activeRecipient = recipient
    },
    [SET_RECIPIENT_CONVERSATIONS](state, recipients) {
        state.recipientConversations = recipients
    },
    [SET_RECIPIENT_CONVERSATION_PAGINATION](state, data) {
        state.pagination.current = data.recipients.current_page
        state.pagination.totalPages = Array(data.recipients.last_page).fill().map((_, i) => i + 1)
        state.pagination.total = data.recipients.total
        state.pagination.perPage = data.recipients.per_page
    },
    [SET_RECIPIENT_CURRENT_CONVERSATION_PAGE](state, page) {
        state.pagination.current = page
    },
    [SET_TEXT_SEARCH](isTextSearch) {
        state.textSearch = isTextSearch
    },
    [SET_UNREAD_SEARCH](isUnreadSearch) {
        state.unread = isUnreadSearch
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
