import * as Swal from "sweetalert2"

export const sweetAlert = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary me-3',
        closeButton: 'btn-close-custom'
    },
    buttonsStyling: false,
    showCloseButton: true,
    closeButtonHtml: '<i class="fas fa-times"></i>'
})

// Add styles to document head
const style = document.createElement('style')
style.textContent = `
    .btn-close-custom {
        background: none;
        border: none;
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 18px;
        color: #6c757d;
        cursor: pointer;
        padding: 0;
        margin: 0;
        line-height: 1;
        opacity: .5;
        transition: opacity .15s;
    }
    .btn-close-custom:hover {
        opacity: 1;
    }
`
document.head.appendChild(style)
